







































































import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isLoadSaveBtn: {
      type: Boolean,
      default: false
    },
    basicUserInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const isDisplay: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const isLoading: any = computed({
      get: () => props.isLoadSaveBtn,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const userInfo: any = computed({
      get: () => props.basicUserInfo,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const isFilled = computed(() => {
      const { email, firstName, lastName } = userInfo.value;
      return !!email && !!firstName && !!lastName;
    });

    return {
      isDisplay,
      userInfo,
      isFilled,
      isLoading
    };
  }
};
